/* 
@font-face {
  font-family: "Graphie-Regular";
  src: url("./assets/fonts/Graphie-Regular.otf");
  src: url("./assets/fonts/Graphie-Regular.otf") format("woff"),
    url("./assets/fonts/Graphie-Regular.otf") format("opentype"),
    url("./assets/fonts/Graphie-Regular.otf") format("svg");
  font-display: swap;
} */

@font-face {
  font-family: 'Graphie-Regular';
  src: url('./assets/fonts/Graphie-Regular.otf');
  src: url('./assets/fonts/Graphie-Regular.otf') format('woff'),
    url('./assets/fonts/Graphie-Regular.otf') format('opentype'),
    url('./assets/fonts/Graphie-Regular.otf') format('svg');
}

:root {
  --adashiDefault: #33277b;
}

body {
  font-size: 16px;
  background: #e5e5e5 !important;
  margin: 0;
  padding: 0;
  font-family: Graphie-Regular !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: auto;
}

/* 
body {
  font-size: 16px;
  margin: 0;
  padding: 0;
  font-family: Graphie-Regular !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media screen and (max-width: 500px) {
  body {
    font-size: 12px !important;
  }

  h2 {
    font-size: 20px !important;
  }
}

@keyframes loadingSpinner {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loadingSpinner {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

.loadingSpinner {
  border: 2px solid #fff;
  position: relative;
  z-index: 3000;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  border-top-color: rgba(0, 0, 0, 0);
  border-left-color: rgba(0, 0, 0, 0);
  width: 30px;
  height: 30px;
  opacity: 0.8;
  border-radius: 50%;
  animation: loadingSpinner 0.7s infinite linear;
  -webkit-animation: loadingSpinner 0.7s infinite linear;
}

.loader-container {
  height: 100vh;
  /* align-items: center;
  display: flex;
  justify-content: center;
  width: 100vw;
  text-align: center;
  line-height: 1.6rem; */
}

.loader-container-inner {
  margin-top: 40vh;
  /* display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 10; */
}
